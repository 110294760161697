import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { acceptInvite, login } from '../../services/api';
import rudderstackEvents from '../../services/rudderstack-events';
import { P2 } from '../../components/Typography';
import { Heading, Paragraph } from '../../components/Typography2';
import Input from '../../components/Input';
import Button from '../../components/Button';
import IconBtn from '../../components/IconBtn';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const STEPS = {
  VALIDATING_CODE: 'validatingCode',
  CODE_VALIDATED: 'codeValidated',
  NO_CODE_PRESENT: 'noCodePresent',
  INVALID_CODE: 'invalidCode',
  PASSWORD_CREATED: 'passCreated',
};

// const LoginPage: ILoginPage = (props) => {
const LoginPage = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [progressStatus, setProgressStatus] = useState('');
  const [isMaillisting, setIsMaillisting] = useState(false);
  const [isButton, setIsButton] = useState(false);
  const [isCuratePage, setIsCuratePage] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const location = useLocation();
  const { setContentType } = useTrackingCode();

  const getVerificationCode = (search) => {
    if (search && search.split) {
      let splitted = search.split('=');
      if (splitted[1]) {
        validateVerificationCode(splitted[1]);
      }
    }
  };

  useEffect(() => {
    document.querySelector('html').style.height = '100%';
    document.querySelector('body').style.height = '100%';
    document.getElementById('root').style.height = '100%';
    return () => {
      document.querySelector('html').style.height = 'auto';
      document.querySelector('body').style.height = 'auto';
      document.getElementById('root').style.height = 'auto';
    };
  }, []);

  const validateVerificationCode = async (code) => {
    setProgressStatus(STEPS.VALIDATING_CODE);
    const resp = await acceptInvite(code);
    if (resp && resp.success) {
      setIsMaillisting(resp.inviteType === 'mailinglist');
      setProgressStatus(STEPS.CODE_VALIDATED);
    }
  };

  useEffect(() => {
    setContentType('login-page');
    if(!props.modal) {
      if (location.search) {
        const path = location.search.split('?');
        if (!path[1].includes('verificationCode')) {
          props.setRedirectPath(path[1]);
        } else {
          getVerificationCode(path[1]);
        }
      }
  
      let parms = location.pathname.split('/');
      let lastparm = parms.pop();
      setIsCuratePage(lastparm.includes('exhibitions/'));
    }
  }, []);

  const checkIsCuratePage = () => {
    return location.pathname.includes('exhibitions/');
  };

  const checkIsExhibition = () => {
    return (
      location.search &&
      location.pathname === '/login' &&
      location.search.includes('?/exhibitions/')
    );
  };

  useEffect(() => {
    return () => {
      console.log('login has unmounted');
    }
  }, [])

  const submit = async () => {
    setLoggingIn(true);
    try {
      const resp = await login(email, password);
      if (resp) {
        rudderstackEvents.onLogin(resp.data.id);
        props.resetExhibitions(true);
        props.setToggleLogin && props.setToggleLogin(false);
        setLoggingIn(false);
        props?.getLoginData(resp);
        props?.login();
        props.onLoginComplete && props.onLoginComplete();
      }
    } catch (e) {
      if (e.message === 'Unverified user') {
        navigate('/verify', {
          state: { email }
        });
      } else {
        setLoggingIn(false);
        setErrorMsg('Email or password is Invalid!');
        setEmailError(true);
        setPasswordError(true);
      }
    }
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    if (email.length && password.length) {
      submit();
    }

  };


  const isPasswordValid = (pass) => {
    return (pass.length >= 6);
  };

  const isEmailValid = (mail) => {
    return /^\S+@\S+\.\S+$/.test(mail) === true;
  };

  useEffect(() => {
    setErrorMsg('');
    setEmailError(false);
    setPasswordError(false);
  }, [email, password]);


  useEffect(() => {
    if (isPasswordValid(password) && isEmailValid(email)) {
        
      setIsButton(true);
    } else {
      setIsButton(false);
    }
    console.log('password => ', isPasswordValid(password), email, isEmailValid(email));
  }, [email, password]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (email.length && password.length) {
          return submit();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const handleClose = () => {
    let parms = location.search.split('/');
    let lastparm = parms.pop();
    if (lastparm.includes('artwork')) {
      let id = lastparm.split('?')[1];
      props.setAciveArtWork(id);
    }
    navigate(-1);
  };

  const renderBanner = () => {
    if (progressStatus === STEPS.CODE_VALIDATED) {
      return <Heading value="Welcome to Vortic" />;
    } else {
      return (
        <div className="form__header">
          <Heading
            className={`${props.toggleLogin ? 'font-family-heading' : ''}`}
            value={props.title ? props.title : 'Please Login '}
          />
          {!props.modal && !props.isEnquireForm && props.handleCloseBtnEvent && (
            <Button
              value="Close"
              className={`button__dark ${props.isCommentsForm ? 'row-reverse' : ''} ${!isButton ? '' : 'hidden'}`}
              iconType={props.isCommentsForm ? 'close' : null}
              type="primary"
              onClick={() => props.handleCloseBtnEvent()}
            />
          )}
        </div>
      );
    }
  };

  const closeLoginPanel = () => {
    if (props.toggleLogin) {
      props.setToggleLogin(false);
    } else {
      props.closeEventLogin ? props.closeEventLogin() : handleClose();
    }
  };

  const handleCancel = (e) => {
    if (props.modal) {
      e.preventDefault();
      props.handleCancel();
      e.stopPropagation();
    } else {
      props.handleCloseBtnEvent();
    }
  };

  return (
    <div className={`form form-page${props.pairVr ? ' form--pairvr' : ''}${isForgotPassword ? ' forgot-password' : ''}`}>
      <form className={`${props.modal ? 'form__container--modal' : ''} form__container login`}>
        {renderBanner()}
        <div className="guides-container">
          {progressStatus === STEPS.CODE_VALIDATED && (
            <p className="dark p2">
              {isMaillisting ? 'Joinded Mail list' : 'Account confirmed.'}
              <br />
              Please fill in your username and password to start collecting.
              <br />
              </p>
          )}
          {(errorMsg === '') && progressStatus !== STEPS.CODE_VALIDATED && (
            <>
              {email && email.length > 0 && isEmailValid(email) === false ? (
                <Paragraph className="invalid" value="Please provide a valid email address." />
              ) : (
                <Paragraph
                  value={`${checkIsCuratePage() ? 'To view this event on Vortic, ' : 'To access more Vortic content '} log in below or register for your free membership to enjoy the full range of events and exhibitions available.`}
                />
              )}
              {!props.pairVr && (
                <>
                  <br />
                  <Paragraph value="We promise to treat your data with complete respect." />
                </>
              )}
            </>
          )}

          {errorMsg && <P2 value={errorMsg} className="dark error" />}
        </div>
        <Input
          value={email}
          label={props.modal ? 'Email Address' : 'Your Email Address'}
          type="email"
          disabled={loggingIn}
          placeholder={props.emailPlaceholder || ''}
          name="email_input"
          className={`padding-zero ${(props.modal || props.isEnquireForm) ? 'input__bright' : 'input__dark'} ${emailError ? 'input__error' : ''} email_Input`}
          onChange={(e) => setEmail(e.target.value)}
          isModalInput={props.modal}
        />
        <Input
          value={password}
          label={props.modal ? 'Password' : 'Your Password'}
          type="password"
          name="password_input"
          disabled={loggingIn}
          placeholder={props.passwordPlaceholder || ''}
          className={`padding-zero ${(props.modal || props.isCommentsForm || props.isEnquireForm) ? 'input__bright' : 'input__dark'} mt-default ${passwordError ? 'input__error' : ''} password_Input`}
          onChange={(e) => setPassword(e.target.value)}
          isModalInput={props.modal}
        />

        <div className="form__grid">
          {!isButton && <></>}
          {!props.isCommentsForm && !props.modal ? (
            <Button
              value="Log in"
              disable={!isButton}
              className={`button__dark ${isButton ? '' : 'hidden'}`}
              type="primary"
              onClick={handleSubmit}
            />
          ) : (
            <>
              {props.modal || props.isEnquireForm ? (
                <div className="forms-buttons-flex">
                  <Button
                    value={props.modal ? 'Log In' : 'Login to Enquire'}
                    className={`button__bright row-reverse ${!isButton ? 'button__disabled' : ''}`}
                    type="secondary"
                    size="small"
                    disable={!isButton}
                    onClick={handleSubmit}
                  />
                  <Button
                    value={props.modal ? 'Cancel' : 'Go Back'}
                    className={`button__white ${props.isCommentsForm ? 'row-reverse' : ''} ${isButton || props.modal ? '' : 'hidden'}`}
                    type="secondary"
                    size="small"
                    onClick={handleCancel}
                  />
                </div>
              ) : (
                <Button
                  value={props.isReply ? 'Login to reply' : 'Login to comment'}
                  className="button__bright row-reverse"
                  type="primary"
                  iconType="next"
                  size="large"
                  disable={isButton}
                  onClick={handleSubmit}
                />
              )}
            </>
          )}
          <p className="paragraph links">
            {props.forgetLinkClick ? (
              <span className={`curate-link ${props.modal ? 'form-anime-link' : ''}`} onClick={props.forgetLinkClick}>
                Forgot Password
                <div className='hover-on-animate' />
              </span>
            ) : (
              <Link to="/forgot-password">Forgot Password</Link>
            )}
            {props.signupLinkClick ? (
              <span className={`cursor-pointer ${props.modal ? 'form-anime-link' : ''}`} onClick={props.signupLinkClick}>
                Create an account
                <div className='hover-on-animate' />
              </span>
            ) : (
              <Link className="text-decoration-none" to="/signup">
                Create an account
              </Link>
            )}
          </p>
        </div>
      </form>
      {isCuratePage == false && !props.modal && !props.isEnquireForm && (
        <IconBtn className={`${props.toggleLogin ? 'login-panel-cross-btn' : 'cross'}`} onClick={closeLoginPanel} type={'brightClose' || 'cross'} secondary />
      )}
    </div>
  );
};

export default LoginPage;
