import React, { useState, useEffect, useReducer } from "react";
import { Heading, Paragraph, Title } from "../Typography2";
import { useDispatch } from 'react-redux';
import "./style.scss";
import IconBtn from "../IconBtn";
import CrossIcon from "../../assets/icons/cross.svg";
import { toInches, isMobileDevice } from '../../utils';
import { constructImageUrl, formatePrice } from '../../utils';
import Spinner from '../../components/Spinner'
import useReadMoreData from "../hooks/useReadMoreData";
import ArtworkCard from "./ArtworkCard";
import rudderstackEvents from "../../services/rudderstack-events";
import ListviewArtworkGrid from '../ListviewArtworkGrid';
import logger from '../../utils/logger';
import { setExhibitionArtists } from "../../containers/Exhibitions/redux/actions";

const CurateSidebarDark = ({
  artworks,
  onClose,
  onClickArtwork,
  isActiveBox,
  setArtwork2dModal,
  setIsLoading,
  isArtwork2dModal,
  isListView,
  roomId,
  isLoadingArtworks,
  state,
  exhibitionId,
    isOldExhibition,
    handleArtworkClick,
    setIs3dViewOpen,
    exhibitionSlug,
    uuid,
    loadingScreen,
    isPreview,
    isArtistVisible
}) => {
  const {
    loadExhibitionArtists,
    loadCurator,
    getExhibitionCurator,
    getArtists,
    getGallery,
    exhibitionCurators,
    artists
} = useReadMoreData(exhibitionSlug || uuid, isPreview);

  const dispatch = useDispatch();

  useEffect(() => {
    if(exhibitionSlug || exhibitionId){
      loadCurator();
      loadExhibitionArtists();
    }
  }, [exhibitionSlug, exhibitionId]);

  useEffect(() => {
    if(artists?.length > 0) {
      dispatch(setExhibitionArtists(artists))
    }
  }, [artists])

  return (
    <aside className="curate-aside-dark">
      {isListView?'':
      <div className="curate-aside-dark-header">
        <Heading className="artworks-number" value={!isListView ? "Artworks" : `${isLoadingArtworks ? 'Loading' : artworks.length} Artworks`} />
        <img className='close' onClick={() => onClose(false, true)} src={CrossIcon} alt="cross" />
      </div>
      }

      <div className="boxes-wrapper">
        <div className="boxes boxes--secondary">
            <div className="listview-cards-wrapper">
              <>
                <div className="gallery-artist listview-gallery-artist">
                      <div className= "gallery">
                        <Heading value={state?.data?.organisation?.data?.type === "vortic" ? "Organisation" : state?.data?.organisation?.data?.type}/>
                        {state?.data?.organisation && <>{getGallery(state?.data?.organisation, state?.data?.organisation?.data?.type === "vortic", true)}</>}
                      </div>
                </div>
              </>
              {exhibitionCurators?.length > 0 &&
              <>
                <div className="listview-divider" />
                <div className="gallery-artist">
                    <div className= "gallery">
                        <Heading value={"Curator"}/>
                        {getExhibitionCurator()}
                    </div>
                </div>
              </>
              }
              {isArtistVisible && artists.length > 0 &&
                <div id="listview-artists">
                  <div className="listview-divider" />
                  <div className="artist listview-artists">
                    <Heading value={artists.length === 1 ? "Artist" : "Artists"}/>
                    <div className="artists">
                      {getArtists()}
                    </div>
                  </div>
                </div>
              }
            </div>
        </div>
      </div>
    </aside>
  );
};

export default CurateSidebarDark;
