import React, { Fragment, useEffect, useRef, useState, useImperativeHandle } from 'react';
import "./ExhibitionCurateLoading.scss";
import { Heading, Label, Paragraph, Timeline } from '../../components/Typography2';
import throttle from 'lodash/throttle';
import IslandLoadingNav from '../Nav/IslandLoadingNav/IslandLoadingNav';
import ImageLoader from '../ImageLoader';
import IconBtn from '../IconBtn';
import NextIcon from '../../assets/icons/next.svg';
import { isMobileDevice } from '../../utils';
import FadeDown from '../FadeDown';

interface IExhibitionLoading {
    isVideoEnabled?: boolean;
    progress: number;
    videoUrl?: string;
    posterUrl?: string;
    imageUrl: string;
    timeLine: string;
    exhibitionName: string;
    galleryName: string;
    description: string;
    roomName?: string;
    isSidePanel?: boolean;
    isIntroVideoEnded?: boolean,
    setIsIntroVideoEnded?: (val: boolean) => void;
    setIsIntoAudioFound?: (val: boolean) => void;
    setIntroAudioPlaying?: () => void;
    setActiveHeader: (boolean) => void;
    skipAnimation: () => void;
    loadingOnComplete: () => void;
    handleMoreClick: () => void;
    descriptionHtml?: any;
    isRoomSwitchModal?: boolean;
    isIntoAudioFound?: boolean;
}

const MAX_CHARACTERS = 255;

const ExhibitionLoading: React.FC<IExhibitionLoading> = React.forwardRef(({
    isVideoEnabled,
    progress,
    videoUrl,
    posterUrl,
    imageUrl,
    timeLine,
    exhibitionName,
    galleryName,
    description,
    setActiveHeader,
    skipAnimation,
    roomName,
    loadingOnComplete,
    handleMoreClick,
    isSidePanel,
    isIntroVideoEnded,
    setIsIntroVideoEnded,
    descriptionHtml,
    isRoomSwitchModal,
    setIntroAudioPlaying,
    setIsIntoAudioFound,
    isIntoAudioFound
}, ref) => {

    // const videoUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4';

    const divRef = useRef(null);
    const paragraphRef = useRef(null);
    const videoRef = useRef(null);
    const timeOutArtworkSelection = useRef(null);
    const [isBackgroundReady, setIsBackgroundReady] = useState(false);
    const [constraintY, setConstraintY] = useState(0);
    const [startY, setStartY] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [calculatedLoaderHeight, setCalculatedLoaderHeight] = useState(0);
    const [isTouchEnd, setIsTouchEnd] = useState(false);
    const [isManualStart, setIsManualStart] = useState(false);
    const [isVideoStarted, setIsVideoStarted] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [rendered, setRendered] = useState(false);
    const myElementRef = useRef<any>(null);
    const [isFirstAudioToggle, setIsFirstAudioToggle] = useState(true);
    const [isVideoVisible, setIsVideoVisible] = useState(false);

    useEffect(() => {
        // Check if the content has been rendered
        if (myElementRef.current && myElementRef.current.innerHTML !== "") {
          setRendered(true);
        }
    }, [myElementRef.current]);

    useEffect(() => {
        if (rendered) {
            handleMutation();
        }
    }, [rendered]);

    const handleMutation = () => {
        appendTruncateDots();
        appendButton();
    };

    const appendButton = () => {
        const button = document.createElement('button');
        button.className = 'loading-more-btn';
        button.textContent = 'More';

        myElementRef.current.appendChild(button);
    };

    const appendTruncateDots = () => {
        const dotSpan = document.createElement('span');
        dotSpan.className = '';
        dotSpan.textContent = ' ... ';

        myElementRef.current.appendChild(dotSpan);
    };


    useEffect(() => {
        if(isIntroVideoEnded && progress === 100){
            skipAnimation();
            timeOutArtworkSelection.current = setTimeout(() => {
                loadingOnComplete();
            }, 2000)
        }
    }, [isIntroVideoEnded, progress])

    useEffect(() => {
        setIsManualStart(false);
        return () => {
            if(timeOutArtworkSelection.current){
                clearTimeout(timeOutArtworkSelection.current);
            }
        }
    }, [])

    const handleTouchStart = (event) => {
        if(isArtworkDescription) {
            setStartY(event.touches[0].clientY);
            setIsTouchEnd(false);

            // Remove transition during touch start
            if (divRef.current) {
                divRef.current.style.transition = 'none';
            }
        }
    };

    const percentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue;
    }

    useEffect(() => {
        if (!paragraphRef.current || window.innerWidth > 767) return;
    
        const resizeObserver = new ResizeObserver(
            throttle(() => {
                const height = paragraphRef.current?.clientHeight - 30;
                setConstraintY(height);
                setStartY(height);
                setCurrentPosition(height);
    
                if (divRef.current) {
                    divRef.current.style.transform = `translateY(${height}px)`;
                    divRef.current.classList.remove('down', 'up');
                }
            }, 200)  // Throttle updates to every 200ms
        );
    
        resizeObserver.observe(paragraphRef.current);
    
        return () => {
            resizeObserver.disconnect();
        };
    }, [description, descriptionHtml]);

    const handleTouchMove = (event) => {
        if (isArtworkDescription) {
            const deltaY = event.touches[0].clientY - startY;
            const newPosition = Math.min(Math.max(currentPosition + deltaY, 0), constraintY);

            setCurrentPosition(newPosition);
            setStartY(event.touches[0].clientY);

            if (divRef.current) {
                divRef.current.style.transform = `translateY(${newPosition}px)`;
                divRef.current.classList.remove('down', 'up');
                if (deltaY > 0) {
                    divRef.current.classList.add('down');
                } else {
                    divRef.current.classList.add('up');
                }
            }
        }
    };

    const handleTouchEnd = () => {
        if(isArtworkDescription) {
            // Determine the final position based on the direction
            const finalPosition = divRef.current.classList.contains('down') ? constraintY : 0;

            // Set the final position and perform any additional logic
            if (divRef.current) {
                divRef.current.style.transition = 'transform 0.3s ease';
                divRef.current.style.transform = `translateY(${finalPosition}px)`;
                setCurrentPosition(finalPosition);
                setIsTouchEnd(true);
            }
        }
    };

    useEffect(() => {
        const totalPercentage = percentage(currentPosition, constraintY);
        const loaderPillHeight = 64;
        const newCalculatedLoaderHeight = (totalPercentage / 100) * loaderPillHeight;
        setCalculatedLoaderHeight(newCalculatedLoaderHeight);
    }, [currentPosition])

    const handlePlay = () => {
        if(videoRef.current){
            videoRef.current.play();
        }
    }

    const handleMediaOnLoad = (mediaType: 'image' | 'video') => {
        setIsBackgroundReady(true)
        setActiveHeader(true);
        // handlePlay();
        setIsImageLoaded(true);
    }

    const handleVideoEnded = (val: boolean) => {
        setIsIntroVideoEnded(val);
    };

    const handleSkipIntro = () => {
        setIsManualStart(true);
        skipAnimation();
        handleVideoEnded(true);
        loadingOnComplete();
    }

    const handleVideoOnPlay = (e) => {
        setIsVideoLoaded(true);
        setIsVideoStarted(true);
        handleVideoEnded(false);
    }

    const onVideoLoaded = (e) => {
        const video = e.target;
        if ((typeof video.mozHasAudio !== "undefined" && video.mozHasAudio) ||
            (typeof video.webkitAudioDecodedByteCount !== "undefined" && video.webkitAudioDecodedByteCount > 0) ||
            Boolean(video.audioTracks?.length)) {
            setIsIntoAudioFound(true);
        } else {
            if(isIntoAudioFound) {
                setIsIntoAudioFound(false);
            }
        }
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return  `<div>${text.substring(0, maxLength)}</div>`
        }
    };

    const truncatedHtml = () => {
        const truncatedText = truncateText(descriptionHtml, MAX_CHARACTERS);
        return { __html: truncatedText };
    };

    const isArtworkDescription = (!!descriptionHtml || (description && description.trim() !== "")) && hasActualContent(descriptionHtml);

    const handleAnchorClick = (event) => {
        if (!event.target.closest('a')) {
            handleMoreClick();
        }
    };

    function hasActualContent(htmlString) {
        // Create a temporary element to parse the HTML safely
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        // Remove leading/trailing whitespace and check for non-whitespace content
        const trimmedText = tempElement.textContent.trim();
        return trimmedText.length > 0;
    }

    const handleOnSpeakerClick = () => {
        if(isIntoAudioFound && videoRef?.current) {
            if(isFirstAudioToggle) {
                videoRef.current.currentTime = 0;
            }
            videoRef.current.muted = !videoRef.current.muted;
            setIntroAudioPlaying();
            setIsFirstAudioToggle(false);
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            toggleAudio: () => {
                handleOnSpeakerClick();
            },
        }),
    );

    const isMobileView = isMobileDevice() || window.innerWidth <= 768;

    useEffect(() => {
        if(isImageLoaded && videoUrl) {
            setIsVideoVisible(true);
        }
    }, [isImageLoaded, videoUrl])

    return (
        <div className={`exhibition-curate-loading ${(isIntroVideoEnded && progress === 100) ? 'exhibition-curate--loaded' : ''} ${isManualStart ? 'exhibition-curate--loaded-manual' : ''}`} onClick={handlePlay}>
            <div className={`exhibition-curate-loading__background-cover ${isVideoStarted ? 'background-cover--video-on' : ''}`}>
                {isVideoVisible && (
                    <video ref={videoRef} playsInline autoPlay muted controls={false} poster={posterUrl} src={videoUrl} onPlay={handleVideoOnPlay} className={isVideoLoaded ? 'media--fade-in' : 'media--fade-out'} onLoadedData={onVideoLoaded} onEnded={() => handleVideoEnded(true)}>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                )}
                <img src={imageUrl} alt="exhibition-cover" onLoad={() => handleMediaOnLoad('image')}  className={isImageLoaded ? 'media--fade-in' : 'media--fade-out'} />
                <div className={`exhibition-curate-loading__background-gradient ${isSidePanel ? 'background-gradient--hide' : ''}`}></div>
            </div>
            <div className={`exhibition-curate-loading__body ${isBackgroundReady ? 'exhibition-curate-loading__body--active' : ''} ${isRoomSwitchModal ? 'exhibition-curate-loading__body--fade-out' : ''}`} ref={divRef}
                style={{ touchAction: 'pan-y', transition: isTouchEnd ? 'transform 0.15s ease-in-out' : 'none' }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}>
                <div className={`exhibition-curate-loading__body-details ${(isSidePanel) ? 'exhibition-curate-loading__body-details--fade-out' : ''} ${!isArtworkDescription ? 'body-details--no-description' : ''}`}>
                    {
                        !isMobileView ?
                        <div className='exhibition-curate-loading__body-details--desktop'>
                            <Heading value={exhibitionName} />
                            <div className='exhibition-curate-loading__body-flex-box'>
                                <div>
                                    <Label className='details-title' value="Presented by" />
                                    <Label className='details-gallery-name' value={galleryName} />
                                </div>
                                <div className='details-divider' />
                                <div>
                                    <Label className='details-title' value="Dates" />
                                    <Timeline value={timeLine} />
                                </div>
                            </div>
                        </div> :
                        <Fragment>
                            <Timeline value={timeLine} />
                            <Heading value={exhibitionName} />
                            <Label value={galleryName} />
                            {isArtworkDescription && <div ref={paragraphRef} style={{ transform: `translateY(${calculatedLoaderHeight}px)`, transition: isTouchEnd ? 'transform 0.15s ease-in-out' : 'none' }}>
                                {(description && description.trim() !== "") ? <p className='paragraph loading-screen-description' onClick={handleAnchorClick}>
                                    {description}&nbsp;
                                    <button className='loading-more-btn'>
                                        More
                                    </button>
                                </p> :
                                (!!descriptionHtml && <div className='exhibition-loading-long-desc loading-screen-description' style={{display: 'inline-block'}}>
                                    <p className='paragraph' onClick={handleAnchorClick} style={{display: 'inline-block'}}>
                                        {/* @ts-ignore */}
                                        <span className='quill-elm-renderer' style={{display: 'inline-block'}} dangerouslySetInnerHTML={truncatedHtml()} ref={myElementRef} />
                                    </p>
                                </div>)
                                }
                            </div>}
                        </Fragment>
                    }
                </div>
                <div className={`exhibition-curate-loading__body-loader ${!isMobileView ? 'exhibition-curate-loading__body-loader--desktop' : ''}`} style={{ position: 'relative', transform: `translateY(${-(currentPosition + (calculatedLoaderHeight / 2))}px)`, transition: isTouchEnd ? 'transform 0.15s ease-in-out' : 'none' }} >
                    <div className={`island-nav island-nav--loading ${progress === 100 ? 'island-nav--loading__room-loaded' : ''}`}>
                        <div className='bg-layer-light' />
                        <div className='bg-layer-dark' />
                        <IslandLoadingNav progress={progress} roomName={roomName} loading={progress < 100} />
                        <button className='exhibition-loading__skip-intro' onClick={handleSkipIntro}>
                            <Label value="Skip Intro" />
                            <img src={NextIcon} alt='arrow-next' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ExhibitionLoading;
