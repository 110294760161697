import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if(process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: "https://b80b1c2eafa3790b9eefb74f91a231d3@o4505664556564480.ingest.us.sentry.io/4507655996637184",
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
    
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,
    
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [
        /^\//,
        /^https:\/\/api\.vortic\.art\//,
      ],
    
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      // prevent event failure if the payload is large and will throw error on sentry ( sentry has max limit on payload for the events, it don't accept payload if it exceeds the limit like in case of curateView )
      beforeSend(event) {
        if (event.message && event.message.includes("LargePayloadError")) {
          return null; // Drop the event
        }
        return event;
      },
    });
}
