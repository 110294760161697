import React, { useEffect, useRef, useState } from "react";
import { isMobileDevice, IsSafari } from "../../utils";
import AboutVideo from '../AboutVideo';
import CollateralLayouts from "../CollateralLayouts";
import useReadMoreData from "../hooks/useReadMoreData";
import { Label } from "../Typography";
import "./style.scss";
const ListViewDiscover = (props) => {
  const {
    exhibitionSlug,
    name,
    startedAt,
    endedAt,
    showVideo,
    signedMobileMedia,
    signedDesktopMedia,
    signedVideoThumbnail,
    isCollateralDetails,
    collateralElement,
    uuid,
    isDateVisible
  } = props;
  const [showMore, setShowMore] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const videoRef = useRef(null);
  const accordionBodyRef = useRef(null);
  const accordionBodyWrapperRef = useRef(null);
  const accordionBodyCollateralContentRef = useRef(null);

  const handleAccordionHeight = () => {
    if (accordionBodyWrapperRef?.current) {
      setBodyHeight(accordionBodyRef.current.clientHeight);
    }
  }

  useEffect(() => {
    handleAccordionHeight();
  }, [accordionBodyWrapperRef, accordionBodyWrapperRef?.current, accordionBodyCollateralContentRef?.current?.clientHeight]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      handleAccordionHeight();
    })
    return () => {
      window.removeEventListener('resize', () => {
        handleAccordionHeight();
      })
    }
  }, [])

  const openFullscreen = () => {
    if (videoRef.current.paused) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      videoRef.current.controls;
      videoRef.current.play();
      // setTimeout(() => {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Safari */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE11 */
        videoRef.current.msRequestFullscreen();
      }
      // }, 0)
    } else {
      videoRef.current.pause();
    }
  };

  const { innerWidth } = window;
  const smallScreen = innerWidth < 990
  return (
    <div className={`accordion ${showMore ? "accordion-dark" : ""}`}>
      <div
        // className={`accordion-header ${showMore ? "accordion-header-active" : ""}`}
        className={`accordion-header`}
        onClick={(e) => {
          setShowMore(!showMore);
        }}
      >
        <h1 className="lineheight-40-3">
          Discover more {!smallScreen && "about the exhibition"}
        </h1>
        <div className="header-right">
          <Label
            className="read-less-label"
            value={smallScreen ? "" : showMore ? "Minimise" : "Expand"}
          />
            <svg
              width="27"
              height="13.5"
              viewBox="0 0 28 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={showMore ? 'active' : ''}
            >
              <path
                d="M0.5 1.25L14 14.75L27.5 1.25"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
        </div>
      </div>
      <div className="accordion-body-wrapper" style={{ height: (!showMore ? 0 : bodyHeight) }} ref={accordionBodyWrapperRef}>
        <div className={`accordion-body`} ref={accordionBodyRef}>
          <div className="list-view-info">
            <Label className="info-item" value={name} />
            {isDateVisible && <Label className="info-item" value={`${startedAt} - ${endedAt}`} />}
            {showVideo && (
              <div className="disabled-video-dummy">
                <AboutVideo
                  videoRef={videoRef}
                  onClick={(e) => { e.stopPropagation(); openFullscreen() }}
                  poster={signedVideoThumbnail}
                  secondary={true}
                  src={
                    IsSafari() || isMobileDevice()
                      ? signedMobileMedia
                      : signedDesktopMedia
                  }
                />
              </div>
            )}
          </div>

          <div className="list-view-details">
            {isCollateralDetails && (
              <div className="collateral-wrapper" ref={accordionBodyCollateralContentRef}>
                <CollateralLayouts layout={collateralElement} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListViewDiscover;
